<template>
  <!--v-row justify="center"-->
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          color="blue"
          label
          text-color="white"
          x-small
          style="cursor:pointer"
          v-bind="attrs"
          v-on="on"
          @click="dialog = true"
        >
          <v-icon 
            left
            x-small
          >
            mdi-file-find
          </v-icon>
          preview
        </v-chip>
      </template>
      <span>Reproducir bucle</span>
    </v-tooltip>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
    <!--v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          style="margin-left: 10px"
        >
          <v-icon>mdi-motion-play-outline</v-icon>
        </v-btn>
      </template>
      <span>Generar MediaFeed</span>
    </v-tooltip-->
      <v-card>
        <v-toolbar
          dark
          color="primary"
          elevation="0"
          style="border-radius:0"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            v-if="lmu"
            v-html="convertTime(lmu)"
          >

          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="dialog = false"
            >
              {{ $t('common.close', locale)}}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row no-gutters>
          <!--v-col
            v-if="lmu"
            cols="12"
            xs="12"
            style="padding-top: 20px;"
          >
            <center>
              <v-card 
                  style="max-width: 800px; padding: 20px 0;" 
                  color="transparent" 
                  elevation="1"
              >
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    xs="12" md="12"
                    style="padding-left: 20px;text-align:center;"
                    v-html="convertTime(lmu)"
                  />
                </v-row>
              </v-card>
            </center>
          </v-col-->
          <v-col
            cols="12"
            xs="12"
            style="padding-top: 20px;"
          >
            <center>
              <v-card
                v-if="size && contents"
                elevation="2"
                class="main-template-preview" 
                :color="isDark ? 'black' : null"
                :style="{ width: `${size.width}px`, height: `${size.height}px` }"
              >
                <custom-carousel
                  :contents="contents"
                  :action="action"
                  :onTick="handleChangeTick"
                  :onChangeContent="handleChangeContent"
                />
              </v-card>
            </center>
          </v-col>

          <v-col
            cols="12"
            xs="12"
            v-if="actualState"
            style="padding-top: 40px;"
          >
            <center>
            <v-card 
                style="max-width: 800px; padding: 20px 0;" 
                color="transparent" 
                elevation="3"
                :loading="t && actualState && actualState.content ? true : false"
            >
                <!--template v-slot:loader="{ isActive }">
                  <v-progress-linear
                    :active="isActive"
                    color="deep-gray"
                    height="1"
                    :model-value="prepareProgress(t, actualState && actualState.content ? actualState.content.duration : null)"
                  />
                </template-->
              <v-row no-gutters>
                <v-col
                  cols="12"
                  xs="12" md="3"
                  style="padding-left: 20px;text-align:left;"
                >
                  <v-btn
                    @click="handleSetAction('prev')"
                    style="margin-right: 10px;"
                    small
                    elevation="2"
                  >
                    <v-icon>mdi-skip-previous</v-icon>
                  </v-btn>
                  <v-btn 
                    @click="handleSetAction(action.action !== 'pause' ? 'pause' : 'play')"
                    style="margin-right: 5px;"
                    :color="action.action !== 'pause' ? '#CCCCCC80' : '#C2C2C2'"
                    :elevation="action.action !== 'pause' ? 1 : 2"
                    small
                  >
                    <v-icon>
                      {{ action.action !== 'pause' ? 'mdi-pause' : 'mdi-play' }}
                    </v-icon>
                  </v-btn>
                  <v-btn 
                    @click="handleSetAction('next')"
                    style="margin-left: 5px;"
                    small
                    elevation="2"
                  >
                    <v-icon>
                      mdi-skip-next
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  xs="12" md="5"
                  style="padding-top:5px;padding-left: 20px;text-align:left;"
                >
                  <small>{{ actualState.content.name }} [ {{t}}seg ]</small>
                </v-col>
                <v-col
                  cols="12"
                  xs="12" md="4"
                  style="padding-top:5px;padding-right: 30px; text-align:right;"
                >
                  <small><b>Item: </b>{{ actualState.actualIndex}} <b>/</b> {{ actualState.count}}</small>
                </v-col>
              </v-row>
            </v-card>
            </center>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  <!--/v-row-->
  </div>
</template>
<script>
import { parseString } from 'xml2js'
//import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import CustomCarousel from './CustomCarousel.vue'
export default {
  components: {
    CustomCarousel,
  },
  props: {
    playLoopConfig: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    size: null,
    contents: null,
    action: {},
    t: null,
    actualState: null,
    darkColor: 'black',
    isDark: false,
    showError: false,
    lmu: null,
  }),
  computed: {
    xml () {
      return this.playLoopConfig.url
    },
  },
  watch: {
    'playLoopConfig.url' () {
      this.handlePrepareXML()
    },
    dialog () {
      this.handlePrepareXML()
    },
  },
  mounted () {
    this.handlePrepareXML()
  },
  methods: {
    convertTime (v) {
      if (!v) return
      let aux = ''
      for (let i = 0; i < v.length; i++) {
        aux += i === 10 ? `T${v[i]}` : v[i]
      }
      if (aux) {
        const options = {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }
        let diff = new Date() - new Date(aux) - 3600000
        diff = diff / 1000

        let totalSeconds = diff

        let hours = Math.floor(totalSeconds / 3600)
        totalSeconds %= 3600
        let minutes = Math.floor(totalSeconds / 60)
        let seconds = totalSeconds % 60

        aux = ` ${new Date(aux).toLocaleDateString('es-ES', options)}, ${new Date(aux).toLocaleTimeString('es-ES')} `

        aux = `Actualizado el ${aux} (-1h) -- [${hours}h ${minutes}m ${seconds|0}s]`
      }
      return aux
    },
    async handleGetSize () {
      return this.scaleSize(this.playLoopConfig)
    },
    prepareMediaContent (type, id, name, url, description, duration) {
      if (!duration || duration.length === 0) duration = 1
      const content = type === 'mp4' ? `<video id="${id}" src="${url}" width="100%" height="100%" loop
            autoplay="autoplay" muted defaultmuted playsinline />`
        : `<img src="${url}" width="100%" height="100%">`

      return {
        content,
        duration,
        type,
        id,
        name,
      }
    },

    async handlePrepareXML () {
      const contents = []
      this.contents = null
      if (!this.dialog) return
      if (!this.xml || this.xml.length < 4) return
      this.size = await this.handleGetSize()
      if (!this.size) {
        alert(' URL no permitida...')
        return
      }
      
      const data = await new Promise((resolve) => fetch(this.xml)
        .then(response => response.text())
        .then(data => resolve(data))
      )
      /*
      const data = await new Promise((resolve) => axios.get(this.xml)
        .then(response => {
          console.log('response', response)
          resolve(response)  
        })
        .catch(error => {
          console.log('error', error)
        })
      )*/
      const results = await new Promise((resolve, reject) => parseString(data, (err, results) => {
        if (err) reject(err)
        else resolve(results)
      }))

      if (!results || !results.rss || !results.rss.channel) return
      const channel = results.rss.channel.shift()
      if (!channel || !channel.item) return
      contents.push(...channel.item.map(item => {
        if (item.pubDate && item.pubDate !== this.lmu) this.lmu = item.pubDate.shift()
        if (item['media:content']) {
          const { url, type, description, duration } = item['media:content'].shift()['$']
          //const id = new Date().toISOString()
          return this.prepareMediaContent(type, uuidv4(), item.title ? item.title.shift() : '',  url, description, duration)
        }
      }))
      this.contents = contents
    },
    handleSetAction (v) {
      this.action = {
        action: v,
        forceUpdate: new Date().toISOString(),
      }
    },
    handleChangeTick (v) {
      this.t = v
    },
    handleChangeContent (v) {
      this.actualState = v
    },
    prepareProgress(t, duration) {
      if (!t || !duration) return 0
      return (duration - t) * 100 / (duration)
    },
    scaleSize (v) {
      const maxHeight = 450
      if (v.height > maxHeight) {
        v.width = v.width * maxHeight / v.height
        v.height = maxHeight
      }
      return v
    },
    handleClose () {
      this.xml = null
      this.showError = false
    },
  },
}
</script>

<style>
* {
  font-family: 'Roboto';
}
</style>

