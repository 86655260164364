<template>
  <v-progress-linear
    v-if="loading"
    indeterminate
  />
  <div v-else style="padding: 20px 0;">
    <p>
      <small style="color: #CCCCCC;">WorkspaceID: </small>{{ID}}
    </p>
    <table style="width: 100%">
      <caption style="background-color:#CCC;padding: 10px;">
        <center>ENLACES</center>
      </caption>
      <tr
        v-for="(item, index) of info.contentTypes"
        :key="index"
        style="background-color: #CCCCCC80;"
      >
        <td style="width: 20%;padding: 5px 10px;">
          {{ item.name }} <small :style="{ color: item.hide ? 'red' : 'green' }">[{{item.hide ? 'HIDE' : 'ACTIVE'}}]</small>
          <br>
          <!--v-chip
            v-if="item.previewConfig"
            class="ma-2"
            color="blue"
            label
            text-color="white"
            x-small
            style="cursor:pointer"
            @click="handlePreview(item.previewConfig)"
          >
            <v-icon 
              left
              x-small
            >
              mdi-file-find
            </v-icon>
            preview
          </v-chip-->
          <small style="color:gray;">[{{item.type}}]</small>
          <br/>
          <small style="color:#CCCCCC;">{{ID}}</small>
          <play-loop
            v-if="item.previewConfig"
            :playLoopConfig="item.previewConfig"
            locale="es"
          />
        </td>
        <td style="padding: 5px 10px;">
          <p v-if="item.cronLink">
            CRON: <a :href="item.cronLink">{{ item.cronLink }}</a>
          </p>
          <p v-if="item.link">
            XML: <a :href="item.link">{{ item.link }}</a>
          </p>
          <span v-else>{{item.type}}</span>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import api from '@/services/api'
import PlayLoop from './playLoop/Index'
export default {
  components: {
    PlayLoop,
  },
  props: {
    ID: {
      type: String,
      required: true
    }
  },
  data: () => ({
      loading: true,
      info: null
  }),
  watch: {
    ID () {
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    async handleGetData () {
      this.loading = true
      this.info = await new Promise(resolve => {
        api.getItem (this.$route.meta.mainPath, 'v1/system/workspaces/', `${this.ID}/info`, false)
          .then(response => {
            resolve(response)           
          })
      })
      this.loading = false
    },
    handlePreview (v) {
      console.log(v)
    },
  }
}
</script>
