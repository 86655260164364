<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ }">
        <upload-image-file
          :backgroundImage="backgroundImage"
          :size="size"
          @change="handleChangeImageFile"
          style="width: 100%"
          :simpleButton="simpleButton"
          :circle="true"
        />
        <!--v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Dialog
          </v-btn-->
      </template>
      <v-card
        color="black"
      >
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="handleCancel"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('selectImage.title', locale) }}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="handleSave"
            >
            {{ $t('common.save', locale) }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <cropper
          class="cropper"
          :src="img"
          :stencilProps="{
            aspectRatio
          }"
          :stencil-component="stencil"
          @change="handleChange"
        />
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  import { mapState } from 'vuex'
  import { Cropper } from 'vue-advanced-cropper'
  import 'vue-advanced-cropper/dist/style.css'
  import utils from '@/services/utils'
  import UploadImageFile from '@/components/uploadImageFile/Index'
  import CircleStencil from './CircleStencil'
  export default {
    components: {
      Cropper,
      UploadImageFile,
    },
    props: {
      size: {
        type: Object,
        default: null,
      },
      oldImg: {
        type: String,
        default: null,
      },
      simpleButton: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        dialog: false,
        fileInfo: null,
        img: null,
        ext: 'png',
        newImg: null,
        backgroundImage: null,
        stencil: CircleStencil,
      }
    },
    computed: {
      ...mapState('app',['locale']),
      aspectRatio() {
        return utils.getAspectRatio(this.size.w, this.size.h)
      },
    },
    watch: {
      oldImg (v) {
        this.newImg = null
       this.backgroundImage = v
      },
    },
    mounted () {
      this.newImg = null
      this.backgroundImage = this.oldImg
    },
    methods: {
      handleChangeImageFile (v) {
        const aux = v.name.split('.')
        this.ext =  aux.length > 1 ? aux[1] : 'png'
        this.fileInfo = v
        this.img = v.base64 // `data:image/${this.ext};base64,${v.data}`
        this.dialog = true
      },
      handleChange({ canvas }) {
        this.newImg = canvas.toDataURL()
        this.backgroundImage = this.newImg
      },
      handleCancel () {
        this.newImg = null
        this.backgroundImage = this.oldImg
        this.dialog = false
      },
      handleSave () {
        if (this.newImg) {
          this.fileInfo.base64 = this.newImg // update img with crop
          this.backgroundImage = this.newImg
        }
        this.$emit('change', {
          ...this.fileInfo,
          ext: this.ext
        })
        this.dialog = false
      }
    },
  }
</script>
<style scoped>
  .cropper {
    /*
    height: 600px;
    background: #DDD;
    */
    background: red;
  }
</style>

