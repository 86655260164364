<template>
  <v-layout wrap>
    <v-flex xs8 style="padding:20px 10px 20px 20px">
      <v-card>
        <!-- <v-card-title>
          prueba
          <v-spacer />
          <v-btn
            color="primary"
            fab
            elevation="0"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-card-title> -->
        <v-card-text style="padding: 0px">
          <custom-table
            :loading="loading"
            :title="$t('sysUser.title', locale)"
            :headers="[
              { text: '', value: 'image', sortable: false, width: '40px' },
              { text: '', value: 'identUser', sortable: false },
              { text: '', value: 'folders', sortable: false },
              { text: '', value: 'actions', sortable: false, align: 'end'},
            ]"
            :onGetData="handleGetData"
            :tableData="users"
            :onEditItem="isReadonly ? null : handleEdit"
            :onDeleteItem="isReadonly ? null : handleDelete"
            :startIndex="startIndex"
            :itemsPerPage="itemsPerPage"
            :count="count"
            :onSearch="handleSearch"
          />
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex xs4 style="padding:20px 20px 20px 10px">
      <v-card
        style="min-height:calc(100vh - 104px);"
        elevation="0"
      >
        <!-- <v-card-title>prueba</v-card-title> -->
        <v-card-text>
          <!-- <user
            :editID="editID"
            :dialog="clientDialog" :onCloseDialog="() => {this.clientDialog = false}"
            :onGetData="handleGetData"
          /> -->
          <user-form
            v-if="showEditForm"
            :id="editID"
            :onCloseForm="handleCloseForm"
          />
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
  import { mapState } from 'vuex'
  import api from '@/services/api'
  import utils from '@/services/utils'
  import CustomTable from '@/components/customTable/Index'
  import UserForm from './UserForm'
  export default {
    components: {
      CustomTable,
      UserForm
    },
    data () {
      return {
        loading: false,
        isReadonly: true,
        showEditForm: false,
        searchValue: '',
        users: [],
        startIndex: 0,
        itemsPerPage: 5,
        count: null
      }
    },
    computed: {
      ...mapState('app',['locale']),
      userID () {
        return utils.getUser()
      },
    },
    mounted () {
      this.handleGetIsReadonly()
      this.handleGetData()
    },
    methods: {
      handleSearch (v) {
        this.searchValue = v
        this.handleGetData(0)
      },
      handleGetIsReadonly() {
        console.log('jhm')
        api.getAllWithoutLimit ('dfiles', `v1/internal/users/${this.userID}/check-readonly`)
          .then(response => {
            this.isReadonly = response === false ? false : true
          })
      },
      handleGetData (startIndex) {
        this.loading = true
        if (startIndex >= 0 ) this.startIndex = startIndex
        api.getAll ('dfiles', 'v1/private/customers/', this.startIndex, this.itemsPerPage, this.searchValue)
          .then(response => {             
            this.count = response.count
            this.users = response.data.map (item => {
              item.image = item.Avatar
              // item.Environments = item.Environments ? JSON.parse(item.Environments).join (', ') : ''
              return item
            })
            this.loading = false
          })
      },
      handleEdit (v) {
        // if new [v] is null else [v] is userID
        this.editID = v
        this.showEditForm = true   
      },
      handleDelete (v) {
        if (v) 
          api.deleteItem ('dfiles', 'v1/private/customers/', v)
          .then(() => {
            this.handleCloseForm()
          }) 
      },
      handleCloseForm () {
        this.handleGetData()
        this.showEditForm = false
      }
    }
  }
</script>
