<template>
  <v-progress-linear
    v-if="loading"
    indeterminate
  />
    <p v-else>{{ users ? users : '' }}</p>
</template>
<script>
  import api from '@/services/api'
  import utils from '@/services/utils'
  export default {
    props: {
      userID: {
        type: String,
        required: true
      },
      locale: {
        type: String,
        required: true
      },
    },
    data () {
      return {
        loading: true,
        users: null
      }
    },
    watch: {
      userID () {
         this.handleGetData()
      }
    },
    mounted () {
      this.handleGetData()
    },
    methods: {
      handleGetData () {
        api.getAllWithoutLimit (this.$route.meta.environment, `private/users/${this.userID}/folders`, false)
        .then(response => {
          this.users = response.map(item => {
            return utils.getTranslation(item.Name, this.locale)
          }).join(', ')
        })
        this.loading = false
      }
    }
  }
</script>
