<template>
  <v-progress-linear
    v-if="loading"
    indeterminate
  />
  <p v-else>{{ users ? users : '' }}</p>
</template>
<script>
  import api from '@/services/api'
  export default {
    props: {
      ID: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        loading: true,
        users: null
      }
    },
    mounted () {
      this.handleGetUsersData()
    },
    methods: {
      handleGetUsersData:async function () {
        const users = await new Promise(resolve => {
          api.getAllWithoutLimit (this.$route.meta.mainPath, `v1/system/workspaces/${this.ID}/users`, false)
            .then(response => {
              resolve(response)           
            })
        })
        const aux = []
        for (const user of users) {
          const name = await new Promise(resolve => {
            api.getItem ('sys', `v1/system/users/`, user.UserID, false)
              .then(response => {
                resolve(response ? response.Name : null)
              })
          })
          if (name) aux.push(name)
        }          
          this.users = aux.join(', ')
          this.loading = false
      }
    }
  }
</script>
