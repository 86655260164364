<template>
  <v-container>    
    <v-row>
      <v-toolbar
        elevation="0"     
      >
        <v-icon large style="margin-right:10px;">{{ editedItem.ID ? 'mdi-account-edit' : 'mdi-account-plus' }}</v-icon>
        <v-toolbar-title>
          {{ editedItem.ID ? $t('customers.editCustomer', locale) : $t('customers.addCustomer', locale) }}
        </v-toolbar-title>
      </v-toolbar>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-text-field
          v-bind="{ 'outlined' : true}"
          v-model="editedItem.Name"
          :label="$t('customers.name', locale)"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-text-field
          v-bind="{ 'outlined' : true}"
          v-model="editedItem.Email"
          :label="$t('customers.email', locale)"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-text-field
          v-model="editedItem.Password"
          v-bind="{ 'outlined' : true}"
          prepend-inner-icon="mdi-lock-reset"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          :label="$t('customers.password', locale)"
          @click:append="showPassword = !showPassword"
          @click:prepend-inner="handleGetNewPassword"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
      >
        <avatar 
          :size="{ w: 100, h: 100}"
          :oldImg="editedItem.Avatar"
          @change="handleChangeAvatar"
          style="margin: 0px;"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
      >
        <v-switch
          v-model="editedItem.Notification"
          :label="$t('customers.notification', locale)"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <action-buttons
          :id="editedItem.ID"
          :onDeleteItem="handleDelete"
          :onSaveItem="handleSave"
          :onClose="handleClose"
          :locale="locale"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import * as sha1 from 'js-sha1'
  import { mapState } from 'vuex'
  import api from '@/services/api'
  import utils from '@/services/utils'
  import ActionButtons from '@/components/ActionButtons'
  import Avatar from '@/components/avatar/Index'
  export default {
    components: {
      ActionButtons,
      Avatar,
    },
    props: {
      id: {
        type: String,
        required: false,
        default: null
      },
      onCloseForm: {
        type: Function,
        required: true,        
      }
    },
    data () {
      return {
        editedItem: {},
        showPassword: false,
        defaultItem: {
          ID: null,
          Name: '',
          Email: '',
          Password: '',
          Avatar: null,
          newMedia: null,
        }
      }
    },
    computed: {
      ...mapState('app',['locale']),
    },
    watch: {
      id (v) {
        if (v) this.handleGetData(v)
        else this.editItem = this.defaultItem
      }
    },
    mounted () {
      if (this.id) this.handleGetData(this.id)
      else this.editItem = this.defaultItem
    },
    methods: {
      handleChangeAvatar (v) {
        this.editedItem.newMedia = v
      },
      handleGetData (v) {
        api.getItem ('dfiles', 'v1/private/customers/', v)
          .then(response => {
            this.editedItem = response
          })
      },
      handleDelete () {
        api.deleteItem ('dfiles', 'v1/private/customers/', this.editedItem.ID)
          .then(() => {
            this.handleClose()
          }) 
      },
      handleSave () {
        if (this.editedItem.Password && this.editedItem.Password.length > 0) this.editedItem.Password = sha1(this.editedItem.Password)
        if (this.editedItem.ID)
          api.updateItem ('dfiles', 'v1/private/customers/', this.editedItem.ID, this.editedItem)
            .then(() => {              
              this.handleClose()
            })  
        else
          api.addItem ('dfiles', 'v1/private/customers/', this.editedItem)
            .then(() => {
              this.handleClose()
            })  
      },
      handleClose () {
        this.onCloseForm ()
      },
      handleGetNewPassword () {
        this.editedItem.Password = utils.getNewPassword()
      }
    }

  }
</script>
