<template>
  <v-card elevation="1" style="border-radius: 0">
    <v-data-table
      :headers="headers"
      :items="tableData"
      class="elevation-0 custom-table"
      hide-default-footer
      :loading="loading"
      :items-per-page="-1"
      :show-expand="source && ['dviewcontentWorkspace'].filter( x => x === source).length > 0 ? true : false"
      single-expand
      item-key="ID"
      :expanded.sync="expanded"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <template-dviewcontent-expand :ID="item.ID" />
        </td>
      </template>
      <template v-slot:[`item.identUser`]="{ item }">
        <a v-if="onEditItem" @click="onEditItem(item.ID)">{{ item.Name }}</a>
        <span v-else>{{ item.Name }}</span>
        <br />
        <span>{{ item.Email }}</span>
      </template>
      <template v-slot:[`item.tIdent`]="{ item }">
        <a v-if="onEditItem" @click="onEditItem(item.ID)">{{ getTranslation(item.Name, locale) }}</a>
        <span v-else>{{ getTranslation(item.Name, locale) }}</span>
      </template>
      <template v-slot:[`item.ident`]="{ item }">
        <a v-if="onEditItem" @click="onEditItem(item.ID)">{{ item.Name }}</a>
        <span v-else>{{ item.Name }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          v-if="onHideItem && !item.NotEditable"
          small
          class="mr-2"
          @click="onHideItem(item.ID, item.Hide)"
          :style="{ color: item.Hide ? 'red' : 'green' }"
        >
          mdi-eye
          {{ item.Hide ? 'mdi-eye-off' : 'mdi-eye' }}
        </v-icon>
        <v-icon
          v-if="onEditItem && !item.NotEditable"
          small
          class="mr-2"
          @click="onEditItem(item.ID)"
        >
          mdi-pencil
        </v-icon>
        <delete-item
          v-if="onDeleteItem"
          :id="item.ID"
          :onDeleteItem="onDeleteItem"
          icon="mdi-delete"
          :locale="locale"
        />
      </template>
      <template v-slot:top>
        <custom-table-title 
          v-if="!hideHeader"
          :onAdd="onEditItem"
          :title="title"
          :icon="icon"
          :onSearch="onSearch"
          :hideAdd="hideAdd"
        />
      </template>
      <template v-slot:[`item.users`]="{ item }">
        <template-users :ID="item.ID" />
      </template>
      <template v-slot:[`item.image`]="{ item }">
        <v-icon
          v-if="!item.image"
          color="primary"
          x-large
          style="padding: 20px 0;"
        >
          mdi-account-circle
        </v-icon>
        <v-avatar
          v-else 
          size="36"
          style="margin: 20px 0;"
        >
          <img
            :src="item.image"
            :alt="item.Name"
          >
        </v-avatar>
      </template>
      <template v-slot:no-data>        
        <v-alert
          v-show="!loading"
          border="bottom"
          color="secondary"
          style="margin-top:20px"
          outlined
          dense
        >
          {{ $t('common.messages.noData', locale) }}
          <v-btn
            v-if="onEditItem && !hideAddOnNoData && !hideAdd"
            @click="onEditItem(null)"
            style="margin-left: 20px;"
          >
          {{ $t('common.newItem', locale) }}
          </v-btn>
        </v-alert>
      </template>
      <template v-slot:[`item.folders`]="{ item }">
        <template-folder-user
          :userID="item.ID"
          :locale="locale"
          :startIndex="startIndex"
        />
      </template>
      <template v-slot:[`item.check`]="{ item }">
        <v-icon>{{ item.check ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}</v-icon>
      </template>
    </v-data-table>
    <pagination
      v-if="!hideFooter"
      :startIndex="startIndex"
      :itemsPerPage="itemsPerPage"
      :count="count"
      :onGetData="onGetData"
      :locale="locale"
    />
  </v-card>
</template>
<script>
  import { mapState } from 'vuex'
  import utils from '@/services/utils'
  import CustomTableTitle from './CustomTableTitle'
  import TemplateUsers from './templates/TemplateUsers'
  import TemplateFolderUser from './templates/TemplateFolderUser'
  import TemplateDviewcontentExpand from './templates/templateDviewcontentExpand/Index'
  import DeleteItem from '../DeleteItem'
  import Pagination from './Pagination'
  export default {
    components: {
      CustomTableTitle,
      TemplateUsers,
      Pagination,
      DeleteItem,
      TemplateFolderUser,
      TemplateDviewcontentExpand,
    },
    props: {
      source: {
        type: String,
        default: null,
      },
      hideHeader: {
        type: Boolean,
        default: false,
      },
      hideFooter: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false
      },
      icon: {
        type: String,
        default: null,
      },
      title: {
        type: String,
        required: false,
        default: null
      },
      headers: {
        type: Array,
        required: true
      },
      tableData: {
        type: Array,
        required: true
      },
      startIndex: {
        type: Number,
        required: false,
        default: null
      },
      count: {
        type: Number,
        required: false,
        default: null
      },
      itemsPerPage: {
        type: Number,
        required: false,
        default: null
      },
      onGetData: {
        type: Function,
        required: true,
        default: null
      },
      onHideItem: {
        type: Function,
        required: false,
        default: null
      },
      onEditItem: {
        type: Function,
        required: false,
        default: null
      },
      onDeleteItem: {
        type: Function,
        required: false,
        default: null
      },
      onSearch: {
        type: Function,
        default: null
      },
      hideAddOnNoData: {
        type: Boolean,
        default: false,
      },
      hideAdd: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      defaultItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      expanded: [],
    }),
    computed: {
      ...mapState('app',['locale']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      getTranslation(v, locale) {
        return utils.getTranslation(v, locale)
      },
      initialize () {
      },

      editItem (item) {
        this.editedIndex = this.tableData.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.tableData.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.tableData.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.tableData[this.editedIndex], this.editedItem)
        } else {
          this.tableData.push(this.editedItem)
        }
        this.close()
      },
    },
  }
</script>
<style>
  .v-data-table.custom-table th span {
    font-weight: 500 !important;
    font-family: Montserrat, sans-serif !important;
    font-size: 1rem !important;
    letter-spacing: .009375em !important;
    line-height: 1.75rem;
  }
</style>
